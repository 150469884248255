import data_package from '../../package.json';


class Env {
    static readonly variables = import.meta.env;
    private static data_project:any = {
        name: data_package.name,
        title: (data_package as any).title,
        version: data_package.version,
        author: (data_package as any)?.author||null,
        date: (data_package as any)?.date||null,
        organization: (data_package as any)?.organization||null,
    };

    private static Value(key: string, value: any) {
        const envValue = Env.variables[key] || Env.variables["VITE_" + key];
        if (envValue !== undefined) {
            return envValue;
        }
        if (value !== undefined) {
            return value;
        }
        throw new Error(`Invalid Env Key: ${key}`);
    }

    static String(key: string, value?: string | null): string | null {
        const envValue = Env.Value(key, value);
        return envValue + "";
    }

    static Number(key: string, value?: number | null): number {
        const envValue = Number(Env.Value(key, value));
        if (!isNaN(envValue)) {
            return envValue;
        }
        throw new Error(`Invalid Env Value for Number: ${key}`);
    }

    static Boolean(key: string, value?: boolean | null): boolean| null {
        const envValue = Env.Value(key, value);
        if (envValue === 'true' || envValue === true) {
            return true;
        }
        if (envValue === 'false' || envValue === false) {
            return false;
        }

        throw new Error(`Invalid Env Value for Boolean: ${key}`);
    }

    static Array(key: string, value?:[]|null, separator:string=','): any[] | null{
        const envValue = Env.Value(key, null);
        if (typeof envValue === 'string') {
            return envValue.split(separator).map(item => item.trim());
        }
        if (value !== undefined) {
            return value;
        }
        throw new Error(`Invalid Env Value for Array: ${key}`);
    }

    static projectData(key: string): any{
        return Env.data_project[key]||null
    }
}

export default Env;
