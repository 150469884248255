// main.tsx

import App from './App'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import 'regenerator-runtime/runtime'
import Env from './utils/env'

// Importa o React Query e seus provedores
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

// Configura uma instância do QueryClient
const queryClient = new QueryClient()

if (Env.String("MODE") === 'development') {
	console.log(
		`${Env.projectData('title')}\n\t` +
		`-> version: ${Env.projectData('version')} in ${Env.projectData('date')}\n\t` +
		`-> by ${Env.projectData('author')?.name}`
	)
	console.log(`API URL being used: ${Env.String("API_URL")}`)
}

const container = document.getElementById('root')
if (container) {
	const root = createRoot(container)
	root.render(
		<QueryClientProvider client={queryClient}>
			<BrowserRouter basename={''}>
				<App />
			</BrowserRouter>
		</QueryClientProvider>
	)
}
